import React from "react";

export default function TermsOfServicePage() {
    return (
        <section id="terms-of-service">
            {`
            TERMS OF SERVICE AGREEMENT
            Last Updated: 03/30/24

            Please read this Terms of Service Agreement ("Agreement") carefully before clicking the "I Agree" button, downloading or using Case Clinical ("Application").
            By clicking the "I Agree” button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.

            If you do not agree to the terms of this Agreement, do not click on the "I Agree" button and do not download or use the Application.

            Choice 360, LLC (“Choice 360”, “we”, or “us”) provides legal automation and other services (“Services”) for lawyers, law firms, clients of law firms, and related persons and companies (“Subscribers”, or “you”).

            This Terms of Service Agreement (“Agreement”) describes the Services we will provide, the cost of those services, and related terms and conditions.

            Whenever a Subscriber registers for one or more of our Services, and each time a Subscriber signs in to any our Services, and each time a Subscriber uses any of the Services, and each time a Subscriber pays for any of the Services, the Subscriber agrees and consents to be bound to all of the terms and conditions of this Agreement.

            A person, law firm, or company becomes a Subscriber by registering for one or more of our Services.

            Please print or download a copy of this Agreement for your records.
            Services Available
            Choice 360 provides a variety of Services, more fully described in various pages of the Choice 360 website at:  [INSERT LINK TO WEBSITE HERE]

            The Services include among other applications:
            CASE CLINICAL:  Law practice management software for, e.g., case file management and storage, legal billing and [INSERT OTHER FEATURES] see: [INSERT LINK TO WEBSITE WITH SOFTWARE DESCRIPTION]. 

            [IF THE PROGRAM IS MODULAR OR COMPRISES DIFFERENT APPLICATIONS, EACH IS DESCRIBED HERE.]

            Each of Choice 360’s Services is sold and/or provided separately.  Choice 360’s Services are provided in accordance with this Agreement.

            Limited License

            Choice 360 owns all right, title, and interest in and to the software, code, systems, inventions, ideas, implementations, processes, and other items in any way relating to or in any way derived from the Services.  No person or entity is authorized to use any, some portion, or all of the Services, except as provided in this Agreement.
            Subject to the terms and conditions of this Agreement and the End-User License Agreement (“EULA”), Choice 360 grants each Subscriber a limited, worldwide, non-exclusive, non-sublicensable, non-transferable, and revocable license to use the Services for which Subscriber registers and pays. No such license is granted to any person (including without limitation any Subscriber) who does not both register for the Services, and, if required, pay for the Services.
            No person or entity is authorized to copy, resell, re-use, reproduce, modify, reverse engineer, duplicate, or exploit access to any, some portion, or all of the Services without express written permission from an Officer of Choice 360. Any actual or attempted copying, resale, re-use, reproduction, modification, reverse engineering, duplication, or access exploitation of or to any, some portion, or all of the Services without express written permission from an Officer of Choice 360 shall be deemed a breach of this Agreement, as well as a violation of any other applicable law.
            Each Subscriber owns all rights in and to the information and data the Subscriber provides to Choice 360. No Subscriber has any right, title, or interest in or to any, some portion, or all of the information and/or data of any other Subscriber.
            Subscriber grants Choice 360 a limited, worldwide, non-exclusive, non-sublicensable, fully paid-up, royalty-free, non-transferable, and revocable license to use the information and data the Subscriber provides to Choice 360.
            Payment for Services
            If Choice 360 implements a “FOR PAY” tier for Services, the following section shall apply:
            Control of payments and other features is accomplished through an administrative interface within the Choice 360 system.  When a Subscriber registers for Services, the Subscriber must designate all users who are authorized to access the Services for which the Subscriber registers (“Users”).  The Subscriber must designate at least One (1) User who will have the credentials to access the administrative interface (“Admin User”).  Subscribers are responsible for the activity of their Users and Admin Users.
            Choice 360 charges for its Services in 30-day increments.  Each monthly increment starts at 12:01 AM Central Time on the first calendar day when Subscriber registers and signs up for Services and ends 30 days later at 12:00 AM Central Time.
            Choice 360 charges for its Services on a per-user basis, or on a per-Subscriber basis.  Each Subscriber must pay for the Services used and to be used by it and its Users.
            Each User will be provided with separate log in credentials for the User to access the Services.  For security purposes, only one User may be logged in to a single User’s account at the same time, and multiple persons using a single User account is not permitted.
            Payments for Services are due in advance.  When a Subscriber registers for Services, the Subscriber must provide a valid credit card for payment of the Services for which the Subscriber registers.  Payments for the Services for which the Subscriber registers will be charged to this credit card.
            If an Admin User adds another User mid-month, the Subscriber will be charged for the Services to be provided in connection with that new User for the remainder of that month on a pro-rated basis.
            All prices for all Services are subject to change upon advance notice by Choice 360 to Subscriber at least Thirty (30) days in advance of the price increase.  These notices will be provided by email at least once to the Admin User email address(es) provided by the Subscriber in the administration interface for Choice 360, with no additional notice being required,
            Free trials automatically expire at the end of the free trial period.  A Subscriber may convert a free trial into a normal paid account with Ninety (90) days following the expiration of the free trial period by selecting the appropriate option in the Choice 360 administrative interface.  Failure to convert a free trial into a normal paid account within Ninety (90) calendar days will result in the Subscriber’s free trial account being deleted.
            If payment for the Services is refused or unsuccessful using the credit card provided, the Subscriber will be notified by email at the email address provided by the Subscriber for such notices in the Choice 360 administration interface.  The Subscriber will have Five (5) calendar days to either replace the credit card or confirm the cancellation of the Services.  Failure to replace the credit card, or confirm the cancellation of the Services, within the Five (5) calendar day period will result in the Services being disabled.  Failure to replace the credit card, or confirm the cancellation of the Services, within Ninety (90) calendar days will result in the Subscriber’s account being deleted.
            Subscribers may elect to have their Services renewed automatically each month, by selecting the option for automatic renewals in the Choice 360 administration interface.  A Subscriber may stop or discontinue the automatic renewal feature by:
            – Deselecting the election for automatic renewals in the Choice 360 administrative interface; or
            – Calling us at:  [INSERT PHONE NUMBER]; or
            – Emailing us at: [INSER EMAIL]; or
            – Mailing a request to:
            Choice 360 LLC
            [INSERT ADDRESS]

            Payments for all automatically renewed Services will be processed on the first day of the month for which the Services will be provided.  Subscriber hereby authorizes Choice 360 to charge the amounts due for all automatically renewed Services against the credit card provided by the Subscriber.
            Alternatively, Subscribers may elect to renew the Services manually each month through the Choice 360 administration interface.  If manual renewals are selected, the Subscriber must manually renew the Services within the Five (5) calendar days of a month in which Services are to be provided.  Failure to manually renew the Services within the Five (5) calendar day period will result in the Services being disabled.  Failure to manually renew the Services within Ninety (90) calendar days will result in the Subscriber’s account and all related data being deleted.
            Payments for the Services are exclusive of any and all federal, state, county, municipal or other taxes that may be due in connection with the Services.  To the maximum extent allowed by law, all Services shall be deemed to have been provided in Torrance, Los Angeles County, California, United States of America.  All payments for Services shall be made free and clear of and without deduction or withholding for any taxes.
            Subscriber is solely and exclusively responsible for paying any and all federal, state, county, municipal or other taxes (as well as any and all related fees, fines, interest, and other amounts) that may be or become due in connection with the Services based on Subscriber’s primary location as indicated by Subscriber when registering for the Services.
            If the taxing authority takes the position that Choice 360 must pay or collect any taxes (as well as any and all related fees, fines, interest, and other amounts) in connection with the Services, the appropriate amount shall be paid by Subscriber, unless Subscriber provides Choice 360 with a valid tax exemption certificate authorized by the appropriate taxing authority.  Subscriber hereby authorizes Choice 360 to charge the amounts asserted to be due by any taxing authority against the credit card provided by the Subscriber.
            If the taxing authority takes the position that Subscriber must deduct or withhold any taxes from any payments for Services, then any amounts due and owing from Subscriber to Choice 360 for the Services shall be increased in order that, after making all required deductions or withholdings, Choice 360 receives an amount equal to the sum it would have received had no such deduction or withholding been made.
            Subscriber is solely and exclusively responsible for any and all fees, charges, or other expenses incidental to using the Services, including without limitation all subscription or other fees charged by any third-party billing or practice management solution used by Subscriber, and all charges for Internet access or other data transmission incurred by Subscriber or any User in accessing Choice 360’s systems
            Cancellations and Refunds
            A Subscriber may cancel the Services for which they register at any time.  A Subscriber may cancel all Services provided to all of its Users, and only as to specific Users.
            A Subscriber may stop or cancel Services by selecting the cancellation option in the Choice 360 administrative interface.  For the protection of the Subscriber, no additional means of cancellation are allowed.
            There are no charges for canceling all or any part of the Services.  In the event any Services are canceled, the automatic renewal triggers for the cancelled Services will be removed.
            When the Subscriber cancels the Services, the Subscriber’s account and all related data will be permanently and completely deleted.
            All payments made for Services are final and non-refundable.  No refunds or credits will be issued, except in the event of a service outage of greater than Forty-Eight (48) consecutive hours.
            Choice 360 in its sole discretion has the right to suspend, discontinue, or cancel providing Services to any Subscriber without notice for any actions that might disrupt Choice 360’s ability to provide Services to Subscriber or to any other person or entity.
            In addition, in the event of any breach by Subscriber of this Agreement, or any breach by Subscriber of any of the representations and warranties contained in this Agreement, Choice 360 in its sole discretion has the right to suspend, discontinue, or cancel providing Services to Subscriber upon advance notice by Choice 360 to Subscriber at least Five (5) days before the change.  These notices will be provided by email at least once to the Admin User email address(es) provided by the Subscriber in the administration interface for Choice 360, with no additional notice being required.
            All features of all Services are subject to change upon advance notice by Choice 360 to Subscriber at least Sixty (60) days before the change becomes effective.  These notices will be provided by email at least once to the Admin User email address(es) provided by the Subscriber in the administration interface for Choice 360, with no additional notice being required.
            Customer Support
            Customer support is available by:
            – Calling us at:  [INSERT PHONE NUMBER] from [INSERT TIMES] Pacific Time, Monday-Friday, except for federal holidays; or
            – Emailing us at: [INSERT EMAIL]; or

            – Mailing a request to:
            Choice 360 LLC
            [INSERT ADDRESS]
            Privacy and Security
            Choice 360 does not and shall not review or examine any of Subscriber Information without express permission from Subscriber, except to the extent said Subscriber Information is anonymized.
            Choice 360 does not and shall not transfer, sell, share, or otherwise reveal any Subscriber Information with or to any person or entity (except to the extent said Subscriber Information is anonymized), for any purpose whatsoever, except as necessary to perform the Services for Subscriber, and except to comply with any applicable law(s), court order(s), or subpoena(s).
            Choice 360 maintains strict security policies and procedures to safeguard Subscriber Information. We will use our best efforts to ensure that no Subscriber Information is in any way disclosed or put at risk of disclosure, including but not limited to:
            (a) requiring any and all persons who have access to Subscriber Information to sign a strict non-disclosure and confidentiality agreement as to the Subscriber Information; and
            (b) immediately and permanently shredding and completely deleting and destroying any Subscriber Information that Choice 360 may have copied, printed, downloaded, or received in electronic, paper or hard-copy form, after the Services are terminated or cancelled pursuant to this Agreement (except solely in order to prevent fraud or abuse); and
            (c) immediately and permanently deleting and destroying any derivatives of or other materials based on Subscriber Information that Choice 360 may have caused to be saved or generated on any computer or in any other account or system other than one of your computers, accounts, and systems, after the Services are terminated or cancelled pursuant to this Agreement (except solely in order to prevent fraud or abuse);

            (d) using our best efforts to prevent any unauthorized release, disclosure, or access by any person or entity of or to any Subscriber Information; and
            (e) immediately notifying Subscriber if Choice 360 knows or suspects that any Subscriber Information has been, may have been, or could be released, disclosed or subjected to unauthorized access; and
            (f) Choice 360's systems are checked, examined, and certified by security experts; and,

            (g) Subscriber Information is encrypted both “in transit" and “at rest." In other words, all transmissions to and from the Services, and all items stored in any Choice 360 app, are all encrypted using [INSERT CRYPTOGRAPHIC METHOD HERE]; [OR ALTERNATELY:]
            You acknowledge and agree that the technical processing and transmission of data associated with the Service may be transmitted unencrypted and involve: (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices such as backups.

            (h) Choice 360 maintains extensive additional features, processes, and controls.
            Subscriber must also attempt to safeguard Subscriber Information by at least:
            (1) protecting and securing any passwords and other credentials that Subscriber or any of its Users, employees, or agents use to access the Services; and
            (2) changing all passwords and other credentials every Ninety (90) days; and
            (3) prohibiting passwords that can be easily guessed, such as dictionary words; and
            (4) prohibiting sharing of passwords and other credentials;
            [DOES CHOICE USE TWO FACTOR AUTHENTICATION?] (5) enabling Choice 360’s two-factor authentication feature, which will require entry of a code texted or emailed to the authorized number or email address of the User or Admin User in order for the User or Admin User to gain access to the Services.

            Due to the fact that the Services involve different billing and practice management solutions, email and telephone communications systems, payment systems, cloud computing server systems, file storage and transfer systems, and other disparate systems over which Choice 360 has no control or right to control, Choice 360 cannot and does not promise, represent, or guarantee that no Subscriber Information will under any circumstances be disclosed or put at risk of disclosure. Choice 360 does not assume any risk and shall not be liable in any way for any data loss, breach, or any unauthorized release, disclosure, or access by any person or entity of or to any Subscriber Information, except in the case of intentional or willful misconduct by Choice 360 or any employee of Choice 360.
            The terms and conditions of this section shall survive any termination or cancellation of this Agreement.
            Service Outages and Business Continuity
            Choice 360 use its commercially reasonable efforts to provide services 24 hours a day, seven days a week. Subscriber acknowledges that from time to time the services may be inaccessible or inoperable for various reasons, including periodic maintenance procedures or upgrades (‘Scheduled Downtime’); or service malfunctions, and causes beyond Choice 360’s control or which are not reasonably foreseeable by Choice 360, including the interruption or failure of telecommunications or digital transmission links, hostile network attacks, or network congestion or other failures. Choice 360 will use commercially reasonable efforts to minimize any other disruption, inaccessibility and/or inoperability of its web servers. Choice 360 has no responsibility for downtime resulting from a Subscriber’s actions.
            Choice 360 limits the likelihood of service outages by providing its Services through enterprise-grade, industry-standard cloud computing server systems, such as those provided by [INSERT SERVER COMPANY HERE SUCH AS AWS]
            Choice 360 regularly conducts encrypted backups of all data stored in its systems, including Subscriber Information.  All such encrypted backups are regularly deleted and destroyed every Ninety (90) days.
            Nevertheless, for added assurance of business continuity, Subscriber must regularly download and backup all of Subscriber’s information and data stored in Choice 360’s systems at least once per week.  Any Admin User may easily download and store the Subscriber’s information and data in a Comma Separated Value (“CSV”) file from the Choice 360 interface for this purpose. [PLEASE CONFIRM THIS IS ACCURATE].
            Limited planned service outages may occur in order to allow Choice 360 to conduct maintenance, repairs, or installation of upgrades.  In order to reduce the possible impact of such limited planed service outages, Choice 360 will use its best efforts to provide notice to Subscriber at least Two (2) business days in advance, and the limited planned service outages will be scheduled to occur after 8:00 PM Pacific Time on weekdays, or on weekend or federal holidays.  These advance notices will be provided by email at least once to the Admin User email address(es) provided by the Subscriber in the administration interface for Choice 360, with no additional notice being required.
            Due to the fact that the Services involve different billing and practice management solutions, email and telephone communications systems, payment systems, cloud computing server systems, file storage and transfer systems, and other disparate systems over which we have no control or right to control, Choice 360 cannot and does not promise, represent, or guarantee that no service outages will ever under any circumstances occur.  Choice 360 does not assume any risk and shall not be liable in any way for any service outage, except in the case of intentional or willful misconduct by Choice 360 or any employee of Choice 360.
            In the case of a service outage in which Subscriber has not been able to access the Services for more than Forty-Eight (48) consecutive hours and Subscriber in in a paid tier of Services, Choice 360 will credit Subscriber with a pro-rated reduction in the next monthly fee due from Subscriber for the Services.  For example, if a 3-day service outage occurs in January of a given year, Choice 360 will reduce the amounts due from Subscriber for the month of February by 10% (i.e., three (3) day outage, divided by 30 days average per month).
            Choice 360 reserves the right at any time, and from time to time, to modify or discontinue, temporarily or permanently, any feature associated with the Service, with or without notice that may be faulty, buggy or may be deemed a potential security risk. Continued use of the Service following any modification constitutes your acceptance of the modification.
            Choice 360 has taken great care in protecting your data, however, Choice 360 does not guarantee that Subscribers’ data will not become corrupt or that data loss will not occur.
            The terms and conditions of this section shall survive any termination or cancellation of this Agreement.
            Additional Terms and Conditions
            No review.  Choice 360 does not review or pre-screen the contents of electronic data uploaded or posted to the Service by Subscribers.
            Third Party Vendors. You acknowledge and agree that Choice 360 may use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.

            Latest Version.  You, your agents and assigns agree to use the latest version or revision of the software at all times. Executing, running or using any version prior to [INSERT VERISON HERE] is a direct violation of these terms of service. You, your agents and assigns acknowledge that not running the latest version of the software puts you and your organization’s data at great risk and release Choice 360, and all its agents from all liability.  Choice 360, its affiliates, and any officer, director, employee, subcontractor, agent, successor, or assign of Choice 360 or its affiliates, shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Choice 360 has been advised of the possibility of such damages).

            Notices and Related Communications.  Choice 360 will send notification emails to the Admin User email address(es) provided by the Subscriber in the administration interface for Choice 360.  The notification emails will advise Subscriber of:  (1) any payment issues; (2) any known service outage issues; (3) confirmations of Services ordered and cancelled; (4) any price increases for any products or services offered by Choice 360; (5) new products or services available from Choice 360; and (6) other issues relating to the functioning and improvement of the Services.  Choice 360 will not otherwise send emails to any of the Users or Admin Users.

            No Advice.  Choice 360 often provides Services by adding on to integrating with products or services of other companies.  You are free to choose any products or services you wish to use.  Choice 360 does provide any advice or make any representations or warranties as to the quality, reliability, usefulness, price, security, or any other characteristic or attribute of any product or service of any other company.  Choice 360 does not assume any risk and shall not be liable in any way for any data loss, breach, service failure or outage, or any other issue or problem relating in any way to the products and/or services of any other company, including without limitation if Choice 360 provides its Services by adding on to or integrating with the other products and/or services.
            Representations and Warranties.  By signing up for one or more of our Services, and each time you sign into your account with Choice 360, and each time you pay for the Services, you represent and warrant to Choice 360 that:
            (a)  You are authorized on behalf of your law firm or company to email, upload, and otherwise provide documents and information to Choice 360; and
            (b)  You are authorized on behalf of your law firm or company to contract with Choice 360 for the Services, and to bind your law firm or company to this Agreement; and
            (c)  Nothing you nor any of your Users submit, enter, import, email, upload, or otherwise provide to Choice 360 is illegal, unlawfully obtained, stolen, or misappropriated; and
            (d)  Everything you and your Users submit, enter, import, email, upload, or otherwise provide to Choice 360 complies with all applicable laws, regulations, and other legal obligations; and
            (e)  Everything you and your Users submit, enter, import, email, upload, or otherwise provide to Choice 360 consists only of documents, information, and data relating to your law firm or company; and
            (f)  You and your Users are not aware that any of the data, documents or other materials you submit, enter, import, email, upload, or otherwise provide to Choice 360 contains any virus, trojan, spyware, malware, or other harmful or malicious software code or device; and
            (g)  You and your Users are not obtaining or using the Services for any illegal purpose; 
            (h)  You are responsible for complying with the CAN-SPAM federal regulation. You are also responsible for supplying an Unsubscribe link in the event that your email is required to under the Federal CAN-SPAM regulation.

            (i)  Neither Subscriber nor any of its Users is violating any law by their use of the Services; 

            (j)  You are not obtaining or using the Services for any personal, family, or household purpose; and,
            (k)  You have fully read and understand the terms and conditions of this Agreement.
            These representations and warranties shall survive any termination or cancellation of this Agreement.
            IMPORTANT:
            Limitation on Liability.  Our Services involve different billing and practice management solutions, email and telephone communications systems, payment systems, cloud computing server systems, file storage and transfer systems, and other disparate systems over which we have no control or right to control.  Accordingly, Choice 360 cannot and does not promise, represent, warranty, or guarantee that it will be able to provide the Services at all times and without service outage(s), without data or security breach(es), and/or without any malfunction, lack of functionality, and/or any other error or issue in connection with the Services.
            To the maximum extent allowed by law, neither Choice 360 nor any of its employees or members shall be liable in any way for any service outage(s), data or security breach(es), malfunction(s), lack of functionality, error, or other issue in any way relating to or in any way arising out of the Services (including without limitation any malfunction, data or security breach, lack of functionality, or any error or other issue caused by any negligence by Choice 360 or any of its employees or members), except in the case of intentional or willful misconduct by Choice 360 or any employee of Choice 360.
            To the maximum extent allowed by law, in no event and under no legal theory shall Choice 360 or any of its employees or members be liable in any way for any consequential, incidental, indirect, punitive or exemplary, loss of revenue, loss of profit, loss of product, business interruption, loss of business opportunity, personal injury, property damage, or any other losses or damages of any kind, regardless of whether or not the losses or damages are or would have been foreseeable, and regardless of whether or not Choice 360 was advised of the possibility of the losses or damages, except in the case of intentional or willful misconduct by Choice 360 or any employee of Choice 360.
            NO WARRANTIES.  ALL SERVICES ARE PROVIDED “AS IS”.  Choice 360 disclaims any and all warranties, whether express, implied, statutory, or other.  For example, and without limitation, Choice 360 disclaims any and all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and any and all warranties arising from course of dealing, usage, or trade practice.

            These limitations on Choice 360’s liabilities are intended to allocate the risks of the Services between the parties to this Agreement.  This allocation is reflected in the pricing for the Services and is a material and essential element of the basis of the bargain between the parties.
             
            The terms and conditions of this section shall survive any termination or cancellation of this Agreement.
            No Third-Party Beneficiaries.  This Agreement has no third-party beneficiaries.
            No Agency.  Choice 360 is an independent contractor services vendor of you and your law firm or company.  You and your law firm or company are customers of Choice 360.  Neither Choice 360, nor any of our employees, contractors, or service providers, are employees, partners, joint venturers, fiduciaries, or agents of you or your law firm or company.  Neither Choice 360, nor any of our employees, contractors, or service providers, are employees, partners, joint venturers, fiduciaries, or agents of any person or company whose products or services are supplemented by or integrated with the Services provided by Choice 360.
            Force Majeure.  The parties will be relieved from fully and completely performing their obligations under this Agreement when circumstances beyond their control (including without limitation natural disasters, terrorist or other man-made disasters, labor strikes, fire, disease or medical epidemics, power grid or internet service failures, and other such circumstances) occur or arise, making performance inadvisable, commercially impracticable, illegal, or impossible.
            Attorney’s Fees.  In any lawsuit, arbitration action, or other proceeding to enforce or interpret any provision of this Agreement (or that is based on this Agreement), the prevailing party is entitled to recover reasonable attorney’s fees, expenses, and costs in connection with the lawsuit, arbitration action, or other proceeding, including without limitation in any appeals. The determination of who is the prevailing party and the amount of reasonable attorney fees to be paid to the prevailing party will be decided by the court or arbitrator in which the matter is tried, heard, or decided.  The terms and conditions of this paragraph shall survive any termination or cancellation of this Agreement.
            Severability.  The terms and provisions of this Agreement are severable.  If any portion, provision, or part of this Agreement is held, determined, or adjudicated to be invalid, unenforceable or void for any reason whatsoever, each such portion, provision or part shall be deemed to extend only as far as allowed by law.  If any portion, provision, or part of this Agreement cannot be limited to extend only as far as allowed by law, that portion, provision, or part shall be deemed to be severed from the remaining portions, provisions or parts of this Agreement and any remaining portions, provisions or parts of this Agreement shall remain valid and enforceable.
            Binding Effect.  This Agreement shall be binding upon and shall inure to the benefit and/or detriment of the parties and their respective heirs, beneficiaries, guardians, administrators, trustees, executors and executrixes, agents, principals, representatives, successors, and assigns.
            Merger and Integration.  The parties represent and acknowledge that this Agreement constitutes a single, integrated, written contract expressing the entire understanding and agreement between and among the parties regarding the Services, and that all prior agreements, contracts, negotiations, promises, offers, acceptances, representations, warranties, covenants, and understandings in any way relating to the Services are merged and integrated into the terms and conditions of this Agreement.
            Counterparts.  This Agreement may be executed in multiple counterparts.  Copies of signatures and electronic signatures shall be deemed to be originals for any and all purposes.
            Amendments.  This Agreement may only be amended or modified in writing signed by the party against whom enforcement of such amendment or modification is sought.
            Choice of Law and Forum.  This Agreement is entered into in Torrance, California, United States of America. To the maximum extent allowed by law, this Agreement and any terms. conditions, rights, remedies, and/or obligations provided for in this Agreement shall be construed and enforced only in accordance with the laws of the United States of America, and the State of California, without regard to the conflicts provisions thereof.  Any and all allegations, disputes, claims, counterclaims, and defenses of any kind whatsoever relating in any way to, or arising in any way out of, this Agreement or any the Services (including without limitation the enforceability of this paragraph) shall be filed and adjudicated only in the state or federal courts having situs in Los Angeles County, California, United States of America. Class action lawsuits, private attorney-general actions, and any other proceeding where someone acts in a representative capacity shall not be allowed and are forever waived. Combining individual proceedings without the consent of all parties also is not allowed and is forever waived. The terms and conditions of this paragraph shall survive any termination or cancellation of this Agreement.
            Limitations Period. Any lawsuit (except intellectual property lawsuits) must be filed within one year from when it first could be filed. Otherwise, it is permanently barred. The terms and conditions of this paragraph shall survive any termination or cancellation of this Agreement.
            Jury Waiver.  The parties hereby waive the right to any jury trial in any action, proceeding, or counterclaim brought by either party against the other.  The terms and conditions of this paragraph shall survive any termination or cancellation of this Agreement.
            Headings.  The underlined headings for each numbered paragraph of this Agreement are used for organizational and reference purposes only and shall not be used to interpret or construe the language of the terms and conditions of this Agreement.
            No Waiver.  Any failure of either party to enforce its rights under this Agreement at any time for any period shall not be construed as a waiver of such rights.
            ** END OF AGREEMENT **
            `}
        </section>
    );
}
